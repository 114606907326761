import React, { useContext } from 'react';
import Header from './sections/Header';
import FormSection from '../../landing/sections/Form';
import { Link } from 'gatsby-plugin-react-i18next';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import AnimationWrapper from '../../landing/components/AnimationWrapper';
import slugify from 'slugify';
import cover1 from '../../assets/image/whitepaper/cover1.png';
import cover2 from '../../assets/image/whitepaper/cover2.png';
import cover3 from '../../assets/image/whitepaper/cover3.png';
import cover4 from '../../assets/image/whitepaper/cover4.png';
import cover5 from '../../assets/image/whitepaper/cover5.png';
import { I18nContext } from 'react-i18next';

const whitepapersCovers = {
  1: {
    img: cover1,
  },
  2: {
    img: cover2,
  },
  3: {
    img: cover3,
  },
  4: {
    img: cover4,
  },
  5: {
    img: cover5,
  },
};

const whitepapersData = require('../../data/whitepapers.json');

const Whitepapers = () => {
  const { t } = useTranslation();
  const context = useContext(I18nContext);
  const currentLanguage = context.i18n.language;

  return (
    <div className='text-white overflow-hidden'>
      <Header />

      <div className='max-w-[1481px] container py-16 mx-auto'>
        <AnimationWrapper from='left' time={0.3} delay={0}>
          <div className='pb-12'>
            <h2 className='text-2xl md:text-3xl lg:text-4xl font-bold text-center lg:text-left'>
              {t('whitepapers.listDescription1')}{' '}
              <span className='text-primary'>
                {t('whitepapers.listDescription2')}
              </span>{' '}
              {t('whitepapers.listDescription3')}
              <br />
              {t('whitepapers.listDescription4')}
            </h2>
          </div>
        </AnimationWrapper>

        <AnimationWrapper from='bottom' time={0.3} delay={0.3}>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-7 xl:grid-cols-3'>
            {whitepapersData.map((whitepaper) => (
              <Link
                to={`/whitepapers/${slugify(whitepaper.title, {
                  lower: true,
                  strict: true,
                })}`}
                className='border w-full border-white border-solid rounded-[16px] overflow-hidden hover:cursor-pointer  max-w-[380px] sm:max-w-[449px] justify-self-center'
                key={whitepaper.id}
              >
                <img
                  src={whitepapersCovers[whitepaper.id].img}
                  alt={whitepaper.title}
                  className='w-full sm:h-[300px] bg-cover'
                />
                <div className='p-6'>
                  {/* rounded badge with color primary and inner black shadow from right bottom corner to left */}
                  <div className='bg-primary text-white text-xs px-2 py-1 rounded-full inline-block whitepaper-badge mb-1'>
                    {currentLanguage === 'es'
                      ? whitepaper.category
                      : whitepaper.category_en}
                  </div>
                  <p className='font-bold text-xl sm:text-3xl capitalize'>
                    {currentLanguage === 'es'
                      ? whitepaper.title
                      : whitepaper.title_en}
                  </p>
                  <p>
                    <span className='text-gray-500 text-sm sm:text-base'>
                      by
                    </span>{' '}
                    Smarttie
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </AnimationWrapper>
      </div>

      <FormSection />
    </div>
  );
};

export default Whitepapers;
