import { Link } from 'gatsby';
import React from 'react';
import AnimationWrapper from '../../../landing/components/AnimationWrapper';
import bgHeroWebp from '../../../assets/image/whitepaper-hero.webp';
import bgHeroJpg from '../../../assets/image/whitepaper-hero.jpg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className='relative'>
      <div className='relative h-[80vh] max-h-[836px] z-[1] container mx-auto grid'>
        <div className='flex justify-center self-stretch flex-col gap-2'>
          <AnimationWrapper from='left' time={0.3} delay={0}>
            <h1 className='text-4xl md:text-5xl lg:text-6xl font-bold mb-3 text-center md:text-left'>
              {t('whitepapers.title')}
            </h1>
            <p className='text-sm  md:text-base lg:text-lg max-w-[550px] text-center md:text-left'>
              {t('whitepapers.description')}
            </p>
          </AnimationWrapper>
        </div>
      </div>
      <div className='absolute w-full h-full top-0 left-0 z-0'>
        <picture>
          <source
            srcSet={bgHeroWebp}
            type='image/webp'
            className='w-full h-full object-cover object-center'
          />
          <img
            src={bgHeroJpg}
            alt='hero-bg'
            className='w-full h-full object-cover object-center'
          />
        </picture>
      </div>
    </div>
  );
};

export default Header;
