import React from 'react';
import { graphql } from 'gatsby';
import Helmet from '../components/Helmet';
import CustomPageWrapper from '../components/CustomPageWrapper/CustomPageWrapper';
import Navbar from '../landing/components/Navbar';
import Info from '../landing/sections/Info';
import Whitepapers from '../sections/whitepapers';

const Index = () => {
  const title = 'Smarttie | Whitepapers';

  const description =
    'Access in-depth research on AI and Data from Smarttie. Discover the latest trends, technologies, and innovations shaping the future of artificial intelligence and data analytics.';

  const banner = true;

  const url = 'https://www.smarttie.ca/whitepapers/';

  const data = {
    title,
    description,
    banner,
    url,
  };

  return (
    <>
      <Helmet data={data} />

      <CustomPageWrapper>
        <div className='text-white'>
          <Navbar top='20' />
          <div className='relative -top-[56px] sm:-top-[72px]'>
            <Info />

            <Whitepapers />
          </div>
        </div>
      </CustomPageWrapper>
    </>
  );
};

export default Index;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
